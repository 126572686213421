import React from "react";
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from '../components/pages/website/hero.component';
import Testimonials from '../components/elements/testimonials/testimonials.component';
import BenefitsListings from '../components/elements/benefits/benefits-listing.component';
import Intro from '../components/pages/website/intro.component';
import StepsList from '../components/elements/steps/steps-list.component';
import PackagesListings from '../components/elements/packages/packages-listings.component';
import Banner from '../components/elements/banner/banner.component';
import Contact from '../components/pages/home/contact.component';

import search from '../images/Website/services/web/SearchResults.svg'
import speed from '../images/Website/services/web/Speed.svg'
import contentManagment from '../images/Website/services/web/Content.svg'
import responsive from '../images/Website/services/web/Responsive.svg'
import unique from '../images/Website/services/web/Unique.svg'
import awareness from '../images/Website/services/web/Awareness.svg'
import experts from '../images/Website/services/web/Experts.svg'
import sales from '../images/Website/services/web/Sales.svg'

import standard from '../images/Website/icons/standard.svg'
import unlimited from '../images/Website/icons/unlimited.svg'
import business from '../images/Website/icons/business.svg'


const WebPage = () => (
  <StaticQuery  
    query={query}
    render={data => {
    
    let content = {
      benefits: [
        {title: "Increased Search Results", icon: search},
        {title: "Increased Speeds", icon: speed},
        {title: "Manage Content", icon: contentManagment},
        {title: "Responsive on All Devices", icon: responsive},
        {title: "Unique Designs", icon: unique},
        {title: "Rich Content", icon: awareness},
        {title: "Industry Experts", icon: experts},
        {title: "Increased Conversions", icon: sales},
      ],
      steps: {
        title: "How we work",
        subTitle: "With lots of tea..",
        steps: [ 
          {title: "Hello", text: "There’s nothing quite like meeting in person. Calls are fine, and email is okay, but real relationships and ideas bloom when we’re face to face. You’ll probably want to get to know us better, and we definitely want to get to know you (and your customers) more before we start our creative project."},
          {title: "Getting creative", text: "We’ll start off by gathering research on your industry, customers, and competitors. We’ll get to know your company and once we’re confident we understand it we’ll then get to work mapping out the best route for your website. Whatever your goals, rest assured we'll make the right decisions to drive results. With your full involvement, we will begin to design the pages and bring in the different elements. We want to work together to help create the designs which you will be proud to show off to potential customers."},
          {title: "Let's develop", text: "Now things get serious! Once the designs have been approved we'll start the development. This is where you’ll start to see the project come to life. Don’t worry, we will continue to keep you in the loop and provide that much needed peace of mind that everything is on track. Our websites are fully search engine optimised and are built using only the best practices. Once built, we’ll continue to work with you to optimise your content and boost your search rankings. - all the good stuff of course!"},
          {title: "Deployment", text: "You have got the perfect website and now it’s time to go live. We’ll do whatever we can to assist you... Need to transfer the domain from an old agency? We’ll handle that. Setting up a new domain? We’ve got you covered. We know you don't want to be doing this stuff, leave it with us and we’ll get your site live in no time."},
          {title: "Maintenance", text: "Whoop Whoop! The website is now live and you're starting to see results... if you’re on one of our maintenance plans we'll be doing monthly updates to keep the site fresh, fast and secure. We’ll also keep an eye on the analytics to ensure we are on track to hitting your targets and goals. We'll send you monthly reports and tweak the site accordingly based on any insights we get. If your maintenance plan includes dedicated monthly hours and we don't use them all, we'll simply roll them onto next month’s quota."},
        ]
      },
      packages: {
        title: "Maintenance packages",
        text: "We also offer hosting and maintenance packages for your ongoing requirements to help keep your website thriving after launch.",
        packages: [
          {
            title: "Standard Plan", 
            icon: standard, 
            price: 40, 
            list: [
              "Maintenance",
              "Weekly Backups",
              "Hardened Security",
              "Increased Optimisation",
            ],
            colour: 'primary'
          },
          {
            title: "Enterprise Plan", 
            icon: unlimited, 
            price: 140, 
            list: [
              "4 Hours Dedicated Updates",
              "Maintenance", 
              "Daily Backups",
              "Hardened Security", 
              "Increased Optimisation",
              "Monthly Reporting",
            ],
            colour: 'secondary'
          },
          {
            title: "Business Plan", 
            icon: business, 
            price: 60, 
            list: [
              "1 Hour Dedicated Updates",
              "Maintenance",
              "Daily Backups",
              "Hardened Security",
              "Increased Optimisation",
              "Monthly Reporting",
            ],
            colour: 'primary'
          }
        ]
      }
    }

    return (
      <Layout noOverlayHeader>
        <SEO
          keywords={[`Website Design`, `Web Design`, `Web Apps`, `Digtial Marketing`]}
          title="Web Design in Ely"
        />
        <Hero 
          title="Jaw-dropping web design that gets results."
          text="Web design that helps you hit your business goals."
          image={data.tablet.childImageSharp.fluid}
          button={{
            link: '/contact',
            title: 'Start today'
          }}
        />
        <section className="max-w-screen-xl mx-auto mb-10 md:mb-20 lg:mb-28 flex flex-wrap px-6 md:px-8 ">
          <div className="w-full md:w-5/12 md:pr-4 lg:pr-6 mb-5 md:mb-0">
            <h2 className="text-3xl md:text-4xl text-primary-default font-semibold mb-2 md:mb-4">Web Design Based in Ely, Cambridgeshire</h2>
            <p className="text-lg md:text-2xl text-black-default max-w-3xl font-light">Prices start from <b>£500</b></p>
          </div>
          <div className="w-full md:w-7/12">
            <p className="font-light">
              We want you to see results from your web design. Our websites are designed to deliver great customer experience. Our focus is to hit the goals you set for your websites. We're all about delivering results you can track. 
              <br/><br/>
              We'll get to understand your business, your customers and your goals. By tailoring a completely unique web experience, we will add real value to your business and deliver a website specifically designed for your goals and targets.
            </p>
          </div>
        </section>
        <BenefitsListings 
          title="Benefits to you"
          text="To keep it simple, our websites are built for results. We’ll keep people on your website for longer with great user experience, we'll make it lightning fast with great optimisation and we promise to help you hit your goals by creating rich content, call-outs, and sales funnels."
          benefits={content.benefits}
          width="sm:w-1/2 md:w-1/4"
        />
        <Intro 
          title="Why Choose Us?"
          subTitle="We’re a no-nonsense agency that's focused on results."
          text="We're developers and designers at heart, everything is done in-house to very high standards to produce real quality websites. Simply put, we believe well-though, well-built and well-managed websites deliver results.If you need to generate further sales, enquiries and prospects, create brand awareness or even just need processes to save your business time – we can help! However big or small your goals may be, it is our job to get it done."
          link={{
            text: 'Lets get started',
            url: "/contact",
            color: "secondary"
          }}
          image={data.choose.childImageSharp.fluid}
        />
        <Testimonials />
        <StepsList {...content.steps} />
        <div id="maintenance">
          <PackagesListings {...content.packages} />
        </div>
        <Banner 
          title="Great customer support" 
          text="Just give us a call any day of the week. We'll pick up. We work flexible hours and are always happy to help our customers any time of the day. It sounds cliche but we really do pride ourselves on our strong customer relationships."
          image={{
            alt: "Peson viewing social media on thier phone",
            src: data.social.childImageSharp.fluid
          }}
          link1={{
            text: "Get in touch",
            url: "/contact",
            color: "secondary"
          }}
        />
        <Contact 
          title="Lets talk"
          text="we would love to show you how we could help grow your business online."
          openingHours="Hours: Monday - Friday: 8:00am - 5:30pm"
          phone="07954 047 882"
          email="hello@volley.digital"
        />
      </Layout>
    )
  }} />
)

export default WebPage;

const query = graphql`
  query {
    tablet: file(relativePath: { eq: "Website/services/web/ely-exotics-mockup.png" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    choose: file(relativePath: { eq: "Website/services/web/why-choose.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    social: file(relativePath: { eq: "Website/services/web/socialmedia.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`